
import React from 'react';
import '../css/Banner.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const BannerHome = () => {

  return (
    <Carousel
      className="home-carousel" // Add this class
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      autoPlay={true}
      infiniteLoop={true}
      emulateTouch={true}
      interval={4500}
    ><div className="banner-slide">
        <img src="/images/banners/DemoBanner09.jpg" alt="DemoBanner1" />
        <div className="banner-text">
          <h2>正心、正念、正學</h2>
          <p>讓孩子的每一步更堅實！</p>
        </div>
      </div>
      <div className="banner-slide">
        <img src="/images/banners/DemoBanner11.jpg" alt="DemoBanner3" />
        <div className="banner-text">
          <h2>持之以恒，正心學習</h2>
          <p>打造孩子無限未來！</p>
        </div>
      </div>
    </Carousel>
  );
};

export default BannerHome;
