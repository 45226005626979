import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/SplitContent.css'; // CSS file for styling

function SplitContent() {

  const { t } = useTranslation();
  return (
    <div className="split-content">
      {/* Left Section (Image) */}
      <div className="split-left">
        <img
          src="/images/freemanLearning.png" // Replace with your image path
          alt="Descriptive Alt Text"
          className="split-image"
        />
      </div>

      {/* Right Section (Text) */}
      <div className="split-right">
        <h2>{t('home_title')}</h2>
        <p>{t('home_content')}
        </p>
      </div>
    </div>
  );
}

export default SplitContent;
