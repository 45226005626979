import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Use NavLink instead of Link
import '../css/Navbar.css';
import logoWithName from '../assets/images/logoWithName.png';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <a href='/'>
          <img
            src={logoWithName}
            alt="Logo"
            className="navbar-logo"
          /></a>

        <div className={`navbar-links ${isMenuOpen ? 'show' : ''}`}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'navbar-link active' : 'navbar-link'
            }
            end
            onClick={closeMenu} // Close menu on link click
          >
            {t('home')}
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? 'navbar-link active' : 'navbar-link'
            }
            onClick={closeMenu} // Close menu on link click
          >
            {t('aboutUs')}
          </NavLink>
          <NavLink
            to="/courses"
            className={({ isActive }) =>
              isActive ? 'navbar-link active' : 'navbar-link'
            }
            onClick={closeMenu} // Close menu on link click
          >
            {t('courses')}
          </NavLink>
          <NavLink
            to="/download"
            className={({ isActive }) =>
              isActive ? 'navbar-link active' : 'navbar-link'
            }
            onClick={closeMenu} // Close menu on link click
          >
            {t('download')}
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? 'navbar-link active' : 'navbar-link'
            }
            onClick={closeMenu} // Close menu on link click
          >
            {t('contact')}
          </NavLink>
        </div>

        {/* Menu Toggle Button */}
        <button className="navbar-toggle" onClick={toggleMenu}>
          ☰
        </button>

        <LanguageSwitcher />
      </div>
    </nav>
  );
}

export default Navbar;
