import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/Download.css';
import ReactGA from 'react-ga4';

function Download() {

  useEffect(() => {
    document.title = "持正學習 Upright Learning - 資源下載"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '資源下載' });
  }, []);

  const { t } = useTranslation();

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '🔼' : '🔽'; // Use emoji or icons
    }
    return ''; // No icon if the column is not sorted
  };

  const gradeButtons = [
    t('grade_p1'),
    t('grade_p2'),
    t('grade_p3'),
    t('grade_p4'),
    t('grade_p5'),
    t('grade_p6'),
  ];

  const subjectButtons = [
    t('subject_chinese'),
    t('subject_english'),
    t('subject_math'),
    t('subject_general_studies'),
  ];

  const gradeMapping = {
    [t('grade_p1')]: 'P1',
    [t('grade_p2')]: 'P2',
    [t('grade_p3')]: 'P3',
    [t('grade_p4')]: 'P4',
    [t('grade_p5')]: 'P5',
    [t('grade_p6')]: 'P6',
  };

  const subjectMapping = {
    [t('subject_chinese')]: 'Chinese',
    [t('subject_english')]: 'English',
    [t('subject_math')]: 'Math',
    [t('subject_general_studies')]: 'General Studies',
  };

  // State to track selected buttons
  const [selectedGrade, setSelectedGrade] = useState(0); // Default: first grade button
  const [selectedSubject, setSelectedSubject] = useState(0); // Default: first subject button
  const [data, setData] = useState(null); // Store fetched data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  // API Call
  const fetchPractices = async () => {
    setLoading(true);
    setError(null);

    const grade = gradeMapping[gradeButtons[selectedGrade]];
    const subject = subjectMapping[subjectButtons[selectedSubject]];

    try {
      const response = await fetch(
        `https://a7y5uz66t5.execute-api.ap-east-1.amazonaws.com/uprightStage/getPractices?grade=${encodeURIComponent(
          grade
        )}&subject=${encodeURIComponent(subject)}`
      );

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const result = await response.json();
      setData(result); // Set the API response
    } catch (err) {
      setError(err.message);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // Fetch practices whenever grade or subject changes
  useEffect(() => {
    fetchPractices();
    // eslint-disable-next-line 
  }, [selectedGrade, selectedSubject]);

  // Sort the data
  const sortedData = React.useMemo(() => {
    if (!data || !sortConfig.key) return data;

    const sorted = [...data];
    sorted.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sorted;
  }, [data, sortConfig]);

  // Handle sorting when a header is clicked
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };


  return (
    <div className="download-page">
      {/* Banner Section */}
      <div className="banner-container">
        <img
          src="/images/banners/DemoBanner07.png" // Replace with your image path
          alt="Download Page Banner"
          className="banner-image"
        />
        <div className="banner-overlay">
          <img
            src="/images/DrUpright.png"
            alt="Dr Upright"
            className="animated-image"
          />
          <div className="banner-text">
            <h1>{t('download_title')}</h1>
          </div>
        </div>
      </div>

      {/* Grade Buttons */}
      <div className="button-grid grades">
        {gradeButtons.map((label, index) => (
          <button
            key={index}
            className={`square-button ${selectedGrade === index ? 'selected' : ''}`}
            onClick={() => setSelectedGrade(index)} // Set selected grade
          >
            {label}
          </button>
        ))}
      </div>

      {/* Subject Buttons */}
      <div className="button-grid subjects">
        {subjectButtons.map((label, index) => (
          <button
            key={index}
            className={`square-button ${selectedSubject === index ? 'selected' : ''}`}
            onClick={() => setSelectedSubject(index)} // Set selected subject
          >
            {label}
          </button>
        ))}
      </div>

      {/* Display API Data */}
      <div className="api-response">
        {loading && <div className="no-data">{t('loading')}</div>}
        {error && <p className="error">{t('error_fetching_data')}</p>}
        {sortedData && sortedData.length > 0 ? (
          <div className="data-container">
            <table className="api-table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('fileName')}>
                    {t('field_fileName')} {getSortIcon('fileName')}
                  </th>
                  <th onClick={() => handleSort('createDate')}>
                    {t('field_createDate')} {getSortIcon('createDate')}
                  </th>
                  <th onClick={() => handleSort('difficulty')}>
                    {t('field_difficulty')} {getSortIcon('difficulty')}
                  </th>
                  <th>{t('field_download')}</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'row-blue' : 'row-yellow'}>
                    <td>{item.fileName}</td>
                    <td>{item.createDate}</td>
                    <td>{item.difficulty}</td>
                    <td>
                      <a href={item.fileLink} target="_blank" rel="noopener noreferrer">
                        {t('download_link')}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          !loading && <div className="no-data">{t('no_data_found')}</div>
        )}
      </div>


    </div>
  );
}

export default Download;
