import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/AboutUs.css'; // Custom CSS for styling and animations
import ReactGA from 'react-ga4';

function About() {

  useEffect(() => {
    document.title = "持正學習 Upright Learning - 關於我們"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '關於我們' });
  }, []);

  const { t } = useTranslation();
  return (
    <div className="about-us">
      {/* Banner Section */}
      <div className="banner-container">
        <img
          src="/images/banners/DemoBanner04.png" // Replace with your image path
          alt="About Us Banner"
          className="banner-image"
        />
        <div className="banner-text">
          <h1 >{t('about_banner_title')}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: t('about_banner_content').replace(/\n/g, '<br />'),
            }}
          ></p>
        </div>
      </div>

      {/* Title and Content Section */}
      <div className="about-content">
        <h2 className="content-title">{t('about_content_title')}</h2>
        <p className="content-text"></p>
        <p className="content-text">
          <strong>{t('upright')} </strong>{t('about_content_text')}
        </p>
        <ul >
          <li><strong>{t('icons.0.title')}：</strong>{t('icons.0.about')}</li>
          <li><strong>{t('icons.1.title')}：</strong>{t('icons.1.about')}</li>
          <li><strong>{t('icons.2.title')}：</strong>{t('icons.2.about')}</li>
          <li><strong>{t('icons.3.title')}：</strong>{t('icons.3.about')}</li>
          <li><strong>{t('icons.4.title')}：</strong>{t('icons.4.about')}</li>
        </ul>
        <p className="content-text">
          {t('about_end')}
        </p>
      </div>
    </div>
  );
}

export default About;
