import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Courses from './pages/Courses';
import ContactUs from './pages/ContactUs';
import HomeworkClass from './pages/HomeworkClass';
import './i18n';
import WhatsAppIcon from './components/WhatsApp';
import Download from './pages/Download';
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize('G-0D3JDXVFZB');

  return (
    <Router>
      <div>
        {/* Navbar */}
        <Navbar />

        {/* Main Content */}
        <div >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/download" element={<Download />} />
            <Route path="/homeworkClass" element={<HomeworkClass />} />
          </Routes>
        </div>
        <WhatsAppIcon />
        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
