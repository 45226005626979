import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/CommandPromptText.css'; // Custom CSS for animation

function CommandPromptText() {
  const { t } = useTranslation();
  return (
    <div className="command-prompt">
      <p className="command-text">
        {t('our_core')}
      </p>
    </div>
  );
}

export default CommandPromptText;
