import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const handleLanguageSwitch = () => {
    const newLang = i18n.language === 'en' ? 'zh' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <button
      onClick={handleLanguageSwitch}
      style={{
        padding: '5px 10px',
        marginLeft: '20px',
        background: '#2A3757',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      {i18n.language === 'en' ? '中文' : 'EN'}
    </button>
  );
}

export default LanguageSwitcher;
