import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/CoursePage.css';
import homeworkImage from '../assets/images/courses/homework.png'; // Import the image
import musicImage from '../assets/images/courses/music.png'; // Import the image
import stemImage from '../assets/images/courses/stem.png'; // Import the image
import studyImage from '../assets/images/courses/study.png'; // Import the image
import ReactGA from 'react-ga4';

function Courses() {

  useEffect(() => {
    document.title = "持正學習 Upright Learning - 課程介紹"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '課程介紹' });
  }, []);

  const { t } = useTranslation();

  return (
    <div>
      <div className="banner-container">
        <img
          src="/images/banners/DemoBanner06.png" // Replace with your image path
          alt="About Us Banner"
          className="banner-image"
        />
        <div className="banner-text">
          <h1 style={{ color: '#FBDF02', fontSize: '2em' }} >{t('course_banner')}</h1>
        </div>
      </div>

      <div className="course-wrapper">
        <div className="course-page">
          <h1 className="course-title">{t('course_page_title')}</h1>
          <div className="course-container">
            <div
              className="background-layer"
              style={{
                backgroundImage: `url(${homeworkImage})`,
              }}
            ></div>
            <div className="course-content">
              <h2 className="content-title">{t('homework_title')}</h2>
              <p className="content-description">{t('homework_description')}</p>
              <p className="content-description"><strong>{t('selling_point')} :</strong>
              </p>
              <ul >
                <li><strong>{t('home_point1')}：</strong>{t('home_point1c')}</li>
                <li><strong>{t('home_point2')}：</strong>{t('home_point2c')}</li>
                <li><strong>{t('home_point3')}：</strong>{t('home_point3c')}</li>
                <li><strong>{t('home_point4')}：</strong>{t('home_point4c')}</li>
              </ul>
              <p className="content-description"><strong>{t('homework_end')}</strong></p>
              <div className="button-container">
                <a href="/homeworkClass"><button className="learn-more-button">{t('learn_more')}</button></a>
                <a href="https://api.whatsapp.com/send?phone=85295933994&text=%E6%88%91%E6%83%B3%E5%A0%B1%E5%90%8D%E5%8A%9F%E8%AA%B2%E8%BC%94%E5%B0%8E%E7%8F%AD
"><button className="enroll-button">{t('enroll_now')}</button></a>
              </div>
            </div>
          </div>

        </div>


        <div className="course-page">
          <div className="course-container">
            <div className="class-full">{t('class_full')}</div>
            <div
              className="background-layer"
              style={{
                backgroundImage: `url(${studyImage})`,
              }}
            ></div>
            <div className="course-content">
              <h2 className="content-title">{t('study_title')}</h2>
              <p className="content-description">{t('study_description')}</p>
              <p className="content-description"><strong>{t('selling_point')} :</strong>
              </p>
              <ul >
                <li><strong>{t('study_point1')}：</strong>{t('study_point1c')}</li>
                <li><strong>{t('study_point2')}：</strong>{t('study_point2c')}</li>
                <li><strong>{t('study_point3')}：</strong>{t('study_point3c')}</li>
                <li><strong>{t('study_point4')}：</strong>{t('study_point4c')}</li>
                <li><strong>{t('study_point5')}：</strong>{t('study_point5c')}</li>
              </ul>
              <p className="content-description"><strong>{t('study_end')}</strong></p>
            </div>
          </div>
        </div>

        <div className="course-page">
          <div className="course-container">
            <div className="comming_soon">{t('comming_soon')}</div>
            <div
              className="background-layer"
              style={{
                backgroundImage: `url(${stemImage})`,
              }}
            ></div>
            <div className="course-content">
              <h2 className="content-title">{t('stem_title')}</h2>
              <p className="content-description">{t('stem_description')}</p>
              <p className="content-description"><strong>{t('selling_point')} :</strong>
              </p>
              <ul >
                <li><strong>{t('stem_point1')}：</strong>{t('stem_point1c')}</li>
                <li><strong>{t('stem_point2')}：</strong>{t('stem_point2c')}</li>
                <li><strong>{t('stem_point3')}：</strong>{t('stem_point3c')}</li>
                <li><strong>{t('stem_point4')}：</strong>{t('stem_point4c')}</li>
                <li><strong>{t('stem_point5')}：</strong>{t('stem_point5c')}</li>
              </ul>
              <p className="content-description"><strong>{t('stem_end')}</strong></p>
              <p className="content-description">{t('stem_end2')}</p>
            </div>
          </div>
        </div>

        <div className="course-page">
          <div className="course-container">
            <div className="comming_soon">{t('comming_soon')}</div>
            <div
              className="background-layer"
              style={{
                backgroundImage: `url(${musicImage})`,
              }}
            ></div>
            <div className="course-content">
              <h2 className="content-title">{t('music_title')}</h2>
              <p className="content-description">{t('music_description')}</p>
              <p className="content-description"><strong>{t('selling_point')} :</strong>
              </p>
              <ul >
                <li><strong>{t('music_point1')}：</strong>{t('music_point1c')}</li>
                <li><strong>{t('music_point2')}：</strong>{t('music_point2c')}</li>
                <li><strong>{t('music_point2')}：</strong>{t('music_point2c')}</li>
              </ul>
              <p className="content-description">{t('music_end')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
