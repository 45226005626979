import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/HomeworkClass.css'; // Custom CSS for styling and animations
import ReactGA from 'react-ga4';
import FAQ from '../components/FAQ';

function HomeworkClass() {

  useEffect(() => {
    document.title = "持正學習 Upright Learning - 功課輔導斑"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '功課輔導斑' });
  }, []);

  const { t } = useTranslation();

  const tableData = [
    {
      target: t('homework.target1'),
      price_5d: t('homework.price_5d1'),
      price_3d: t('homework.price_3d1'),
      time: t('homework.time1'),
    },
    {
      target: t('homework.target2'),
      price_5d: t('homework.price_5d2'),
      price_3d: t('homework.price_3d2'),
      time: t('homework.time2'),
    },
    {
      target: t('homework.target3'),
      price_5d: t('homework.price_5d3'),
      price_3d: t('homework.price_3d3'),
      time: t('homework.time3'),
    },
  ];


  return (
    <div className="about-us">
      {/* Banner Section */}
      <div className="banner-container">
        <img
          src="/images/banners/DemoBanner08.jpg" // Replace with your image path
          alt="About Us Banner"
          className="banner-image"
        />
        <div className="banner-text">
          <h1 >{t('homework_class_banner_title')}</h1>
        </div>
      </div>

      <div className="about-content">
        <h1>{t('tutoring.title')}</h1>
        <p>{t('tutoring.description')}</p>
        <ul>
          <li>
            <strong>{t('tutoring.features.smallGroup.title')}</strong>: {t('tutoring.features.smallGroup.description')}
          </li>
          <li>
            <strong>{t('tutoring.features.mentors.title')}</strong>: {t('tutoring.features.mentors.description')}
          </li>
          <li>
            <strong>{t('tutoring.features.customPlan.title')}</strong>: {t('tutoring.features.customPlan.description')}
          </li>
          <li>
            <strong>{t('tutoring.features.weeklyPractice.title')}</strong>: {t('tutoring.features.weeklyPractice.description')}
          </li>
          <li>
            <strong>{t('tutoring.features.flexibleSchedule.title')}</strong>: {t('tutoring.features.flexibleSchedule.description')}
          </li>
          <li>
            <strong>{t('tutoring.features.interactiveLearning.title')}</strong>: {t('tutoring.features.interactiveLearning.description')}
          </li>
        </ul>
        <p>{t('tutoring.callToAction')}</p>
      </div>


      {/* Table Section */}
      <div className="homework-table">
        <table>
          <thead>
            <tr>
              <th>{t('target')}</th>
              <th>{t('five_days_a_week')}</th>
              <th>{t('three_days_a_week')}</th>
              <th>{t('time')}</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'row-odd' : 'row-even'}>
                <td>{row.target}</td>
                <td>{row.price_5d}</td>
                <td>{row.price_3d}</td>
                <td>{row.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="button-container">
        <a href="https://api.whatsapp.com/send?phone=85295933994&text=%E6%88%91%E6%83%B3%E5%A0%B1%E5%90%8D%E5%8A%9F%E8%AA%B2%E8%BC%94%E5%B0%8E%E7%8F%AD
"><button className="enroll-button">{t('enroll_now')}</button></a>
        <br /> <br />
      </div>

      <br />
      <FAQ />

    </div>
  );
}

export default HomeworkClass;

