import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import '../css/AnimatedIcons.css';

const icons = [
  '/images/icons/icon1.png',
  '/images/icons/icon2.png',
  '/images/icons/icon3.png',
  '/images/icons/icon4.png',
  '/images/icons/icon5.png',
];

function AnimatedIcons() {
  const { t } = useTranslation();
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const [visibleIcons, setVisibleIcons] = useState(0);

  const handleOpenDialog = (index) => {
    setOpenDialogIndex(index);
  };

  const handleCloseDialog = () => {
    setOpenDialogIndex(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleIcons((prev) => (prev < icons.length ? prev + 1 : prev));
    }, 1000); // Change every second

    return () => clearInterval(interval);
  }, []);

  const translations = t('icons', { returnObjects: true }); // Fetch translations as an array

  return (
    <div className="animated-icons">
      {translations.map((translation, index) => (
        <div
          key={index}
          className={`icon-container ${index < visibleIcons ? 'visible' : ''}`}
        >
          <img src={icons[index]} alt={translation.title} className="icon-image" />
          <div className="icon-details">
            <h3 className="icon-title">{translation.title}</h3>
            <p className="icon-content">{translation.content}</p>

            <button
              className="icon-more-button"
              onClick={() => handleOpenDialog(index)}
            >
              {t('details')}
            </button>
          </div>
        </div>
      ))}
      {/* Dialog Rendering */}
      {translations.map((translation, index) => (
        <Dialog
          key={index}
          open={openDialogIndex === index}
          onClose={handleCloseDialog}
        >
          <DialogContent>
            <h2>{translation.dialogTitle}</h2>
            <p>{translation.dialogContent}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      ))}
    </div>
  );
}

export default AnimatedIcons;
