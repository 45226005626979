import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import '../css/Footer.css';
import logoWithWhiteName from '../assets/images/logoWithWhiteName.png'; // Import your logo
import { useTranslation } from 'react-i18next';


function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo */}
        <div className="footer-logo">
          <img src={logoWithWhiteName} alt="Company Logo" />
        </div>

        {/* Navigation Links */}
        <div className="footer-links">
          <Link to="/">{t('home')}</Link>
          <Link to="/about">{t('aboutUs')}</Link>
          <Link to="/courses">{t('courses')}</Link>
          <Link to="/download">{t('download')}</Link>
          <Link to="/homeworkClass"> {t('homework_title')}</Link>
          <Link to="/contact"> {t('contact')}</Link>
        </div>

        {/* Footer Information */}
        <div className="footer-info">
          <p>&copy; {new Date().getFullYear()} Upright Learning. All rights reserved.</p>
          <p>
            Follow us on:
            <a href="https://www.facebook.com/uprighthk/" target="_blank" rel="noopener noreferrer"> Facebook</a> |
            <a href="https://www.instagram.com/upright_hk/" target="_blank" rel="noopener noreferrer"> Instagram</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
