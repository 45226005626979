import React, { useEffect } from 'react';
import BannerHome from '../components/BannerHome';
import AnimatedIcons from '../components/AnimatedIcons';
import SplitContent from '../components/SplitContent';
import SplitContent1 from '../components/SplitContent1';
import SplitContent2 from '../components/SplitContent2';
import CommandPromptText from '../components/CommandPromptText';
import ReactGA from 'react-ga4';

function Home() {

  useEffect(() => {
    document.title = "持正學習 Upright Learning - 主頁"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '主頁' });
  }, []);

  return (
    <div>
      <BannerHome />
      {/* Other content for the homepage */}

      {/* Animated Icons Section */}
      <AnimatedIcons />

      <CommandPromptText />
      {/* Split Content Section */}
      <SplitContent />
      <SplitContent1 />
      <SplitContent2 />
    </div>
  );
}

export default Home;
