import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';

// Translation resources
const resources = {
  en: {
    translation: {
      welcome: "Welcome to Your Tutoring Center",
      aboutUs: "About Us",
      courses: "Courses",
      contact: "Contact",
      homeDescription: "Helping students achieve excellence in their academic journey.",
      home: "Home",
      home_title: "Feynman Learning Technique",
      home_content: "Did you know? Learning isn’t just about taking notes and memorizing; with the Feynman Learning Technique, your child can learn faster and more effectively! The core idea of this method is to have your child explain what they’ve learned in simple words, just like a little teacher. During this process, they’ll identify areas they don’t fully understand and actively revisit the material until they grasp it completely. This not only deepens their understanding but also improves their logical thinking and communication skills. The Feynman Technique is engaging and efficient, making learning enjoyable and boosting your child’s confidence. Give it a try and offer your child a whole new way to learn!",
      home_title_1: "Help Your Child Fall in Love with Learning Using a Scientific Approach!",
      home_content_1: "At our tutoring center, we utilize the Simon Learning Method to help children learn effectively and understand deeply. This method focuses on structuring knowledge, using chunking and problem-based learning to ensure children not only remember concepts but also apply them with ease. Students will learn to integrate new knowledge into their existing understanding, simplifying complex topics. We also incorporate real-world simulations and hands-on practice, allowing children to gain a sense of accomplishment through problem-solving. This approach not only boosts learning efficiency but also enhances logical thinking and creativity. Join us today, and let your child discover the joy of learning with the Simon Learning Method and grow happily and confidently!",
      home_title_2: "Make Learning Efficient and Fun for Your Child!",
      home_content_2: "At our tutoring center, we teach children how to use the Pomodoro Technique to focus and complete their learning tasks effectively. This scientifically proven and engaging time management method uses 25 minutes of focused study + 5 minutes of relaxed break cycles to help children overcome procrastination and improve concentration. Guided by our professional teachers, students can break their tasks into manageable units, steadily completing them while gaining a sense of achievement. We also provide fun tools like timers and challenge charts to turn focused learning into a rewarding habit! Join us today to make every moment count and let your child experience the joy of learning and growth!",
      download: "Download",
      details: "More",
      our_core: "#Our Core Learning Philosophy",
      icons: [
        {
          title: "Small-Class Teaching",
          content: "Six students per class to ensure each student receives adequate attention.",
          dialogTitle: "Small-Class Teaching",
          dialogContent: "In small classes, we focus on individualized attention to maximize learning outcomes.",
        },
        {
          title: "Customized Learning Materials",
          content: "Tailor-made tutorials and exercises for each student every week.",
          dialogTitle: "Customized Learning Materials",
          dialogContent: "Our materials are designed specifically for each student to address their unique needs.",
        },
        {
          title: "Positive Learning",
          content: "Teach students a positive learning attitude.",
          dialogTitle: "Positive Learning",
          dialogContent: "A positive mindset helps students approach challenges with confidence and motivation.",
        },
        {
          title: "Optimal Environment",
          content: "Provide a safe and comfortable learning environment.",
          dialogTitle: "Optimal Environment",
          dialogContent: "Our classrooms are designed to be comfortable and distraction-free for effective learning.",
        },
        {
          title: "Enhancement",
          content: "Adopt a \"recognize first, memorize later\" approach to enhance knowledge retention.",
          dialogTitle: "Enhancement",
          dialogContent: "Enhancement",
        },
      ],
    },
  },
  zh: {
    translation: {
      tutoring: {
        title: "全科功課輔導班",
        description: "我們的課程專為小學生設計，致力於滿足不同孩子的學習需求，幫助他們全面進步。",
        features: {
          smallGroup: {
            title: "4-6人小班教學",
            description: "確保每位學生都能獲得充分關注，導師根據學習進度進行即時調整，讓教學更精準。"
          },
          mentors: {
            title: "正副導師雙重細心",
            description: "正副導師聯手指導，細心解答疑問，提供全面支持，幫助孩子更好掌握知識。"
          },
          customPlan: {
            title: "個性化學習計劃",
            description: "根據學生的學習目標和能力，量身定制學習計劃，幫助孩子針對性提升每科成績。"
          },
          weeklyPractice: {
            title: "每週量身訂制練習題材",
            description: "根據孩子的進步情況設計專屬練習，鞏固所學知識，查漏補缺。"
          },
          flexibleSchedule: {
            title: "靈活的上課時間",
            description: "提供多樣化上課時段，讓學習與生活輕鬆兼顧。"
          },
          interactiveLearning: {
            title: "互動式學習模式",
            description: "採用討論與實踐結合的方式，鼓勵學生積極參與，提升思維與表達能力，讓學習更有趣。"
          }
        },
        callToAction: "選擇我們，給孩子量身打造高效學習之路，成就卓越未來！"
      },
      upright: "持正學習",
      welcome: "歡迎來到您的補習中心",
      aboutUs: "關於我們",
      courses: "課程介紹",
      contact: "聯絡我們",
      homeDescription: "幫助學生在學術旅程中取得卓越成就。",
      home: "主頁",
      download: "資源下載",
      details: "詳情",
      name: "姓名",
      our_core: "#我們的核心學習理念",
      about_banner_title: "持正學習",
      about_banner_content: "持正前行\n助力每一步學習",
      about_content_title: "關於我們",
      about_content_text: "是一家致力於為學生提供全面、個性化學習方案的教育中心。我們相信，每個孩子都擁有獨特的學習潛力，而我們的使命是通過專業指導和正向引導，幫助學生發揮潛能，成就未來。",
      about_end: "我們的專業團隊由經驗豐富的教育專家和充滿熱誠的導師組成，他們秉持教育初心，用心教導每一位學生。通過創新教學方式和貼心服務，我們努力為學生和家長創造優質的學習體驗。",
      contact_us_title: "聯絡我們",
      contact_us_content: "讓學習從這裡開始飛躍！",
      contact_us_info: "聯絡資訊",
      course_banner: "滿足不同學習需求",
      course_page_title: "課程介紹",
      telephone: "電話",
      homework_title: "功課輔導班",
      study_title: "成績提升加強班",
      stem_title: "青少年兒童STEM編程課程",
      music_title: "青少年兒童音樂斑",
      homework_description: "功課輔導班是我們中心的王牌課程，專為提升學生日常學習成效而設。課程全面輔助學生處理日常功課，準備學校的默書測驗，並針對當日學校所教內容進行重溫和鞏固",
      home_point1: "輔助功課",
      home_point2: "準備默書",
      home_point3: "重溫學校所教",
      home_point4: "量身訂造練習",
      home_point1c: "專業導師以小班形式指導，確保學生理解並正確完成當日功課。",
      home_point2c: "針對學校日常默書內容，制定有效的練習方法，提升準備效率。",
      home_point3c: "每天針對學校課堂內容進行復習，解決學習盲點，確保學生穩步前進。",
      home_point4c: "每星期根據學生的學習進度和需求，設計專屬練習題，讓學生在週末重溫，進一步鞏固知識。",
      homework_end: "適合對象： 小一至小六學生，特別是家長因繁忙未能提供充足指導的孩子，讓我們的專業輔導為孩子的學習保駕護航。",
      study_description: "成績提升加強班專為希望快速提升學業表現的學生而設，課程重點針對學科難點和個人薄弱環節進行針對性輔導，幫助學生鞏固基礎、突破瓶頸，實現成績的顯著提升。",
      study_point1: "學科重點精講",
      study_point2: "個性化強化練習",
      study_point3: "小班教學",
      study_point4: "學習策略指導",
      study_point5: "定期測評",
      study_point1c: "針對學校教學大綱中的核心內容進行深入解析，幫助學生牢牢掌握學科重點。",
      study_point2c: "根據每位學生的學習進度和需求設計專屬練習題，針對性解決學科薄弱環節。",
      study_point3c: "採用少班制教學，導師可為每位學生提供更多的個人關注與指導。",
      study_point4c: "傳授高效學習方法，幫助學生提高學習效率，應對測驗與考試壓力。",
      study_point5c: "定期進行模擬測試，檢視學習進度，及時調整教學策略，確保成績穩步提升。",
      study_end: "適合對象： 希望改善學科表現或備考的學生。有志在校內測驗和考試中脫穎而出的學子。有特定學科薄弱點的學生，希望獲得專業支持。",
      stem_description: "青少年兒童 STEM 編程課程專為啟發孩子創意思維和培養解難能力而設。課程結合 STEM（科學、技術、工程與數學）理念，透過編程活動讓學生掌握基礎編程知識，培養未來必備的科技素養和創新能力。",
      stem_point1: "循序漸進的編程教學",
      stem_point2: "結合 STEM 理念",
      stem_point3: "實際項目操作",
      stem_point4: "啟發創新與合作",
      stem_point5: "適合不同年齡段",
      stem_point1c: "從簡單的圖形化編程入手，逐步過渡到邏輯編程與實踐應用，適合初學者與進階學習者。",
      stem_point2c: "將科學、數學與技術應用於課堂項目中，讓學生理解跨學科解決問題的方法。",
      stem_point3c: "設計和完成趣味編程項目，例如簡單遊戲、動畫製作或機器人控制，增強學生的動手能力與成就感。",
      stem_point4c: "鼓勵團隊合作與創意表現，培養學生在科技領域中的協作能力與創新精神。",
      stem_point5c: "課程內容根據學生年齡與能力設計，提供針對性教學與挑戰。",
      stem_end: "適合對象：對科技與編程有興趣，希望打下基礎的孩子。希望透過實踐掌握未來核心技能的學子。",
      stem_end2: "STEM 編程課程不僅是學習技能的起點，更是啟發創新與探索未來的關鍵一步。我們致力於打造寓學於樂的環境，激勵孩子們勇敢迎接科技時代的挑戰！",
      music_description: "青少年兒童音樂班為孩子提供一個啟發音樂興趣、發掘音樂才能的學習平台。課程結合基礎理論與實踐，讓學生在快樂的學習氛圍中掌握音樂技巧、提升表達能力，並培養對音樂的長久熱愛。",
      music_end: "音樂不僅是學習的起點，更是一段快樂成長的旅程。我們致力於用音樂打開孩子的心靈，讓他們在旋律中發現自我、成就未來！",
      music_point1: "多元音樂基礎教學",
      music_point2: "樂器學習",
      music_point3: "團體與個別課程",
      music_point1c: "涵蓋音樂理論、基本樂譜閱讀和節奏訓練，為學生建立堅實的音樂基礎。",
      music_point2c: "提供鋼琴、結他、小提琴等多種樂器選擇，學生可根據興趣選擇專攻方向。",
      music_point3c: "根據學生需求安排團體班或個人課程，確保每位學生都能獲得適合的指導。",
      selling_point: "課程特色",
      address: "地址",
      message_us: "立即查詢",
      home_title: "讓孩子學得更快、更好，成為自己的小老師！",
      home_content: "我們引入了費曼學習法，幫助孩子深入理解知識。這種方法讓孩子用自己的話解釋學到的內容，像「小老師」一樣講給別人聽，發現理解中的不足，然後回頭補充學習，直到完全掌握。我們還結合互動和趣味活動，讓孩子在講解的過程中建立自信，提升表達能力。費曼學習法不僅能加深知識記憶，還能激發學習興趣，讓孩子在輕鬆的氛圍中快樂成長！加入我們，一起見證孩子的進步與成長！",
      home_title_1: "讓孩子愛上學習，用科學方法輕鬆掌握知識！",
      home_content_1: "我們採用西蒙學習法，幫助孩子高效學習、深度理解。西蒙學習法注重將知識結構化，通過分塊學習（Chunking）和問題導向教學，讓孩子不僅記住知識，更能靈活運用。孩子將學會如何將新知識融入已有的理解框架，將複雜內容化繁為簡。我們還會通過真實場景模擬和實踐，讓孩子在解決問題中獲得成就感！這種方法不僅提升學習效率，還能激發邏輯思維和創造力。快來加入我們吧，讓孩子用西蒙學習法探索知識的樂趣，實現快樂成長！",
      home_title_2: "讓學習更高效，孩子更專注！",
      home_content_2: "在我們的學習中心，孩子將學習如何運用番茄工作法，專注完成每一個學習任務。這是一種科學且趣味十足的時間管理方法，通過25分鐘高效學習+5分鐘輕鬆休息的循環，幫助孩子克服拖延，提高專注力。在專業老師的引導下，孩子們可以根據自己的學習需求，將任務分解成小單元，逐步完成並取得成就感。我們還會提供趣味工具，例如計時器和學習挑戰表，讓孩子將專注學習變成一種習慣！快來加入我們吧，讓每一分每一秒都充滿學習的快樂和成長的喜悅！",
      class_full: "反應熱烈，暫時未有空缺",
      comming_soon: "即將推出",
      learn_more: "了解更多",
      enroll_now: "立即報名",
      download_title: "獨家資源",
      homework_class_banner_title: "個性化學習，全方位提升",
      homework_class_content_title: "全科功課輔導班——全面提升，成就卓越",
      homework_class_content_text: "在持正學習補習社，我們專注於孩子的全科學習需求，為小學生提供高效的全科功課輔導班。我們採用4-6人小班教學，讓每位學生都能獲得足夠的關注與指導。課堂中，正副導師聯手，細心解答孩子的疑難，確保他們的學習過程更流暢、更高效。我們的特色在於每週量身訂制周末練習題材，根據學生的學習進度和能力水平進行個性化設計，讓孩子真正做到查漏補缺。課堂採用互動式學習模式，鼓勵孩子積極參與討論與練習，提升思考與表達能力。加入我們的全科功課輔導班，讓孩子在全面指導與關愛中，扎實學習基礎，成就輝煌未來！",
      grade_p1: "小一",
      grade_p2: "小二",
      grade_p3: "小三",
      grade_p4: "小四",
      grade_p5: "小五",
      grade_p6: "小六",
      subject_chinese: "中文",
      subject_english: "英文",
      subject_math: "數學",
      subject_general_studies: "常識",
      loading: "Loading....",
      download_link: "下載",
      field_fileName: "練習名稱",
      field_createDate: "日期",
      field_difficulty: "難易度",
      field_download: "下載",
      no_data_found: "未有數據",
      target: "對象程度",
      three_days_a_week: "每週3天",
      five_days_a_week: "每週5天",
      time: "時間",
      faq_title: "功課輔導班 常見問題",
      faq: {
        question1: "不是當月1號開始參加，學費點計？",
        answer1: "首次參加的同學,只要一併繳付次月學費，首月學費可獲按比例收費優惠",
        question2: "功課輔導班 每日要幾長時間？",
        answer2: "每間學校的功課量，每位學生做功課的速度，都不相同。因此我們提供 每日2小時 及 每日3小時 的功輔班課程，給予大家選擇最適合自己情況的課程長度。",
        question3: "同學生病／因事缺席，可以獲得補堂嗎？",
        answer3: "一般情況下未能提供補堂、退款或調堂。但在特殊情況下，可根據實際情況進行酌情協商。",
        question4: "功課輔導班會指導學生哪些科目？",
        answer4: "「全科」功課輔導班，主要會指導學生的中文、英文、數學科功課。",
        question5: "功課輔導班老師核對後的功課，仍然有錯誤？",
        answer5: "很多輔導班強調讓學生的功課100%正確，但這往往是由老師代做，學生無法真正學到知識，考試成績可能反而下降。我們的功課輔導班目標不是讓功課100% 正確，而是讓學生學習如何應用自己的知識完成課題。老師提供支援和指導，但偶爾出現錯漏是正常的，還需家長理解。      我們相信，讓學生學會知識比功課完全正確更重要，請家長多多體諒和支持。",
        question6: "如果當天沒有功課，就不用來了？",
        answer6: "即使遇上考試後、學校旅行或特別日子沒有功課，我們仍建議學生前來中心上課。我們會提供額外練習或工作紙，確保補習時間被充分利用，讓學生能持續學習、進步。"
      },
      homework: {
        target1: "P1-P2",
        price_5d1: "$2,500",
        price_3d1: "$1,900",
        target2: "P3-P4",
        price_5d2: "$2,600",
        price_3d2: "$2,000",
        target3: "P5-P6",
        price_5d3: "$2,800",
        price_3d3: "$2,100",
        time1: "星期一至五 15:00 – 19:00",
        time2: "星期一至五 15:00 – 19:00",
        time3: "星期一至五 15:00 – 19:00",
      },
      icons: [
        {
          title: "少班教學",
          content: "六人一班，確保每個學員得到充分照顧",
          dialogTitle: "小班教學",
          dialogContent: "在小班中，我們注重個別化的關注，以最大化學習效果。",
          about: "注重小班制的學習環境，確保每位學生都能得到充足的關注和支持。",
        },
        {
          title: "訂制教材",
          content: "每星期為每個學員度身訂製教程練習",
          dialogTitle: "訂制教材",
          dialogContent: "我們的教材專為每位學生設計，以滿足其獨特需求。",
          about: "根據每位學生的學習需求，量身打造專屬教材，提升學習效果。",
        },
        {
          title: "正向學習",
          content: "引導學員保持專注，及持續正向的學習思維",
          dialogTitle: "正向學習",
          dialogContent: "正面的思維幫助學生以自信和動力應對挑戰。",
          about: "培養積極的學習態度，讓學生在輕鬆的氛圍中學習。",
        },
        {
          title: "優適環境",
          content: "提供安全舒適，讓家長放心的學習環境",
          dialogTitle: "優適環境",
          dialogContent: "我們的教室設計舒適，無干擾，促進高效學習。",
          about: "提供舒適且專業的學習空間，讓學生專注於提升自我。",
        },
        {
          title: "強化知識",
          content: "採用先識後記學習，強化知識記憶",
          dialogTitle: "強化知識",
          dialogContent: "採用先識後記學習，強化知識記憶。",
          about: "採用先識後記學習，強化知識記憶。",
        },
      ],


    },
  },
};

i18n
  //.use(LanguageDetector) // Detects user's language
  .use(initReactI18next) // Initializes react-i18next
  .init({
    resources,
    fallbackLng: 'zh', // Default language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
