import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/ContactUs.css';
import ReactGA from 'react-ga4';

function ContactUs() {

  useEffect(() => {
    document.title = "持正學習 Upright Learning - 聯絡我們"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: '聯絡我們' });
  }, []);

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Validate form data
  const validate = () => {
    let tempErrors = {};
    if (!formData.name.trim()) tempErrors.name = 'Name is required.';
    if (!formData.phone.trim()) tempErrors.phone = 'Phone is required.';
    else if (!/^\d+$/.test(formData.phone))
      tempErrors.phone = 'Phone must be a valid number.';
    if (!formData.message.trim()) tempErrors.message = 'Message is required.';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        // Generate unique ID using Date.now()
        const id = Date.now();

        // Prepare data to send
        const dataToSend = {
          id, // Include the unique ID
          ...formData, // Include the form data
        };

        // Call the API Gateway endpoint
        const response = await fetch(
          'https://a7y5uz66t5.execute-api.ap-east-1.amazonaws.com/uprightStage/messageEnquiry',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
          }
        );

        if (response.ok) {
          alert('Form submitted successfully!');
          setFormData({ name: '', phone: '', email: '', message: '' }); // Reset form
        } else {
          const errorData = await response.json();
          console.error('Error response:', errorData);
          alert('Failed to submit the form. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('Failed to submit the form. Please try again.');
      }
    }
  };

  return (
    <div className="contact-us">
      {/* Banner Section */}
      <div className="banner-container-contact">
        <img
          src="/images/banners/DemoBanner05.png"
          alt="Contact Us Banner"
          className="banner-image-contact"
        />
        <div className="banner-text">
          <h1>{t('contact_us_title')}</h1>
          <p>{t('contact_us_content')}</p>
        </div>
      </div>

      {/* Contact Information */}
      <div className="contact-info">
        <h2>{t('contact_us_info')}:</h2>
        <br />
        <ul>
          <li><a href="tel:95933994"><strong>{t('telephone')} : </strong> 95933994</a></li>
          <li><a href="https://api.whatsapp.com/send?phone=85295933994" target='_blank' rel="noreferrer"><strong>WhatsApp : </strong> 95933994</a></li>
          <li><a href="mailto:info@uprighthk.com"><strong>Email : </strong>info@uprighthk.com</a></li>
          <li><a href="https://www.facebook.com/uprighthk/" target='_blank' rel="noreferrer"><strong>Facebook : </strong>uprighthk</a></li>
          <li><a href="https://www.instagram.com/upright_hk/" target='_blank' rel="noreferrer"><strong>Instagram : </strong>upright_hk</a></li>
          <li><a href="https://maps.app.goo.gl/bY2qJXe46aD4NJNH6" target='_blank' rel="noreferrer"><strong>{t('address')} : </strong> 匯賢一號 - 屯門新合里3號(屯門站B出口)</a></li>
        </ul>
      </div>

      {/* Google Map Section */}
      <div className="map-container">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1075.2581171936213!2d113.97080785252699!3d22.393522112159335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3403fb60a7553e99%3A0x825a2e83a246fe3f!2z5Yyv6LOi5LiA6Jmf4oCn6ZuL5bOw!5e0!3m2!1szh-TW!2skr!4v1732005783912!5m2!1szh-TW!2skr"
          referrerpolicy="no-referrer-when-downgrade"
          title="Upright Learning HK"

          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy">
        </iframe>
        <div className="map-overlay">
          <h2>Upright Learning Hong Kong</h2>
        </div>
      </div>

      {/* Contact Form */}
      <div className="contact-form">
        <h2>{t('message_us')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>{t('name')} *</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <small>{errors.name}</small>}
          </div>

          <div className="form-group">
            <label>Phone *</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && <small>{errors.phone}</small>}
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Message *</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <small>{errors.message}</small>}
          </div>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
